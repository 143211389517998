import React from 'react';
import '../../styles/loginOrRegister.scss';
import Layout from '../layout';
import { Link } from 'gatsby';

export default function LoginOrRegister() {
    return (
        <Layout>
            <div className="card">
                <div className="textBlock">
                    <h1 className="pageHeadline">Take action for people & planet</h1>
                    <div className="pageHeroSubhead loginSubhead">
                        Respond to climate change by discovering personalized, positive steps we can
                        take to care for our planet and communities. It's our future to choose.
                    </div>
                    <Link className="create-account-button" to="/signup">
                        <span>Create Account</span>
                    </Link>
                    <Link className="login-button" to="/login">
                        <span>Sign In</span>
                    </Link>
                </div>
            </div>
        </Layout>
    );
}
